<template>
  <v-card>
    <v-toolbar v-if="titleEditable" dense>
      <v-text-field v-if="editTitle" v-model="editableTitle" label="Group Title" append-icon="fal fa-save" single-line hide-details outlined dense @click:append="saveTitle" @keydown.enter="saveTitle"></v-text-field>
      <v-toolbar-title v-else>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('deleteGroup')">
            <v-icon color="error">fal fa-trash</v-icon>
          </v-btn>
        </template>
        <span>Delete this group</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="editTitle=!editTitle">
            <v-icon>fas fa-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit the name of this group</span>
      </v-tooltip>
    </v-toolbar>
    <v-row>
      <v-col cols="12" :md="listEditable ? 6 : 12" style="padding-bottom:0">
        <v-card>
          <v-card-text v-if="people.length === 0">No people are in this group. Use the box to the right to search for people and click on their photo to add them to the group.</v-card-text>
          <v-card-text v-else>
            <p>To remove a user from this group, click on their photo.</p>
            <photo :people="listPeople" :double-cols="listEditable" @click="removeFromGroup"></photo>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="listEditable" cols="12" md="6" style="padding-bottom:0">
        <v-card>
          <v-card-text>
            <p>Search for users to add to this group. Click on a photo to add them.</p>
            <v-row>
              <v-col cols="6">
                <v-select v-model="searchField" :items="fieldOptions" label="Search Field"></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-if="searchField === 'name'" v-model="searchText" label="Search Text"></v-text-field>
                <v-autocomplete v-else v-model="searchText" :label="searchLabel" :items="searchAutoCompleteItems"></v-autocomplete>
              </v-col>
            </v-row>
            <photo :people="searchResults" :double-cols="true" @click="addToGroup"></photo>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import Photo from '@/components/greatscots/Photo'

export default {
  components: {
    Photo
  },
  props: {
    title: {
      type: String,
      default: 'New Group'
    },
    titleEditable: {
      type: Boolean,
      default: true
    },
    listEditable: {
      type: Boolean,
      default: true
    },
    people: {
      type: Array
    }
  },
  setup (props, { root, emit }) {
    const editTitle = ref(false)
    const editableTitle = ref('')
    function saveTitle () {
      emit('save', { title: editableTitle.value })
      editTitle.value = false
    }
    const fieldOptions = ref([
      { text: 'Name', value: 'name' },
      { text: 'Department', value: 'location.department' },
      { text: 'Building', value: 'location.building' },
      { text: 'Hall', value: 'location.hall' }
    ])
    const searchField = ref('name')
    const searchText = ref('')
    const searchResults = ref([])
    const searchLabel = computed(() => {
      for (let i = 0; i < fieldOptions.length; i++) {
        if (fieldOptions[i].value === searchField.value) {
          return 'Select ' + fieldOptions[i].text
        }
      }
      return 'Search ' + searchField.value
    })
    const searchAutoCompleteItems = ref([])
    watch(searchField, (field, prevField) => {
      if (prevField !== field) {
        searchText.value = ''
        if (field !== 'name') {
          root.$feathers.service('directory/people').find({ query: { $distinct: field } }).then(({ data }) => {
            searchAutoCompleteItems.value = []
            for (let i = 0; i < data.length; i++) {
              searchAutoCompleteItems.value.push(data[i]._id)
            }
          })
        }
      }
    })

    watch(searchText, (text) => {
      let query = {
        active: true,
        $limit: searchField.value === 'name' ? 20 : 50,
        $skip: 0,
        $sort: {
          'name.last': 1,
          'name.first': 1
        }
      }
      switch (searchField.value) {
        case 'name':
          if (searchText.value.trim() !== '') {
            if (searchText.value.search(',') > 0) {
              let temp = searchText.value.split(',')
              query['name.last'] = { $regex: temp[0].trim(), $options: 'i' }
              query['name.first'] = { $regex: temp[1].trim(), $options: 'i' }
            } else if (searchText.value.search(' ') > 0) {
              let temp = searchText.value.split(' ')
              query['name.first'] = { $regex: temp[0].trim(), $options: 'i' }
              query['name.last'] = { $regex: temp[1].trim(), $options: 'i' }
            } else {
              query.$or = [
                { 'name.last': { $regex: searchText.value.trim(), $options: 'i' } },
                { 'name.first': { $regex: searchText.value.trim(), $options: 'i' } }
              ]
            }
          }
          break
        default:
          // All search options besides name are currently from a select menu so we don't need to do a fuzzy search like with name
          query[searchField.value] = searchText.value
          break
      }
      if (props.people.length > 0) {
        query['_id'] = { $nin: [] }
        for (let i = 0; i < props.people.length; i++) {
          query._id.$nin.push(props.people[i])
        }
      }
      root.$feathers.service('directory/people').find({ query }).then((res) => {
        searchResults.value = res.data
      })
    })

    function addToGroup (id) {
      emit('add', id)
      root.$feathers.service('directory/people').get(id).then((data) => {
        listPeople.value.push(data)
      })
      for (let i = 0; i < searchResults.value.length; i++) {
        if (searchResults.value[i]._id === id) {
          searchResults.value.splice(i, 1)
          return
        }
      }
    }

    function removeFromGroup (id) {
      emit('remove', id)
      for (let i = 0; i < listPeople.value.length; i++) {
        if (listPeople.value[i]._id === id) {
          listPeople.value.splice(i, 1)
          return
        }
      }
    }

    const listPeople = ref([])
    onMounted(() => {
      if (props.titleEditable) editableTitle.value = props.title
      if (props.people.length > 0) load()
    })
    watch(() => props.people, () => {
      load()
    })
    async function load () {
      let query = { $limit: 0, _id: { $in: [] } }
      for (let i = 0; i < props.people.length; i++) {
        query._id.$in.push(props.people[i])
      }
      if (props.people.length < 50) {
        query.$limit = 50
        let { data } = await root.$feathers.service('directory/people').find({ query })
        listPeople.value = data
      } else {
        let { total } = await root.$feathers.service('directory/people').find({ query })
        query.$limit = 20
        let arr = []
        for (let i = 0; i < total; i += 20) {
          query.$skip = i
          let { data } = await root.$feathers.service('directory/people').find({ query })
          for (let j = 0; j < data.length; j++) {
            arr.push(data[j])
          }
        }
        listPeople.value = arr
      }
    }

    return {
      editTitle,
      editableTitle,
      saveTitle,
      fieldOptions,
      searchField,
      searchText,
      searchResults,
      searchLabel,
      searchAutoCompleteItems,
      addToGroup,
      removeFromGroup,
      listPeople,
      load
    }
  }
}
</script>
